/* eslint-disable no-param-reassign */
/* eslint-disable no-dupe-keys */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Swipe from '../../components/common/swipe';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Sidebar from '../../components/universities/sidebar';
import * as styles from './Recruitment.module.scss';
import ChooseList from '../../components/chooseList';
import FSTableList from '../../components/FSTableList';
import MobileDropdownChooseList from '../../components/mobile-dropdown-chooselist';
import { changeIsLoadingAllData, saveJobFilters } from '../../state/app';
import {
  getKeyWord, getDevice,
} from '../../utils/util';
import { getFSApi } from '../../utils/feishu';
import {
  fetchFSJobCategory, fetchFSJobCitiesByWebsite,
} from '../../utils/api';

import { list2tree, throttle } from '../../utils/helper';

class Recruitment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: [],
      // 移动端的职业筛选
      showChooseList: false,
      // 保存 api 获取完的职业类型 => 平铺的 list
      jobCategory: [],
    };
  }

  // eslint-disable-next-line consistent-return
  fetchCategory = (param = { page_token: '' }, doneFetchCbk = () => { }) =>
    getFSApi(fetchFSJobCategory, param)
      .then((res) => {
        const { page_token, items, has_more } = res;
        this.setState({ jobCategory: this.state.jobCategory.concat(items) });
        if (has_more) {
          this.fetchCategory({ page_token }, doneFetchCbk);
        } else {
          doneFetchCbk();
        }
      });

  fetchPosition = (param) => getFSApi(fetchFSJobCitiesByWebsite, param)

  initData = (data, lang) => {
    data.forEach((item) => {
      if (!item.description && lang === 'zh') {
        item.description = item.name.zh_cn;
      } else {
        item.description = item.name.en_us;
      }
      if (!item.id) {
        item.id = item.city_code;
      }
    });
    return data;
  }

  initJobFilters = (_jobFilters, validJobFilters, props) => {
    const { websiteId, copyright, lang } = props;
    const jobFilters = validJobFilters
      .filter((job) => _jobFilters.includes(job.key));
    jobFilters.map((jobFilter) => {
      jobFilter.data = [{
        id: `all_${jobFilter.description}`, // 全部 = 无筛选条件 = 没有 id
        parent_id: '0', // 飞书的 root 标准
        description: copyright.filter_all_description,
        children: [],
      }];
      if (jobFilter.key === 'category') {
        this.fetchCategory({}, () => {
          const data = this.initData(this.state.jobCategory, lang);
          const jobCategory = list2tree(data,
            copyright.filter_all_children_prefix,
            copyright.filter_all_children_suffix);
          jobFilter.data = jobFilter.data.concat(jobCategory);
          // TODO 优化 Done 目前获取所有数据完成的标志是 category 获取完
          this.props.saveJobFilters(jobFilters);
          let jobCategoryData = jobFilter.data;
          // eslint-disable-next-line no-return-assign
          jobCategoryData.forEach((item) => item.children = []);
          jobCategoryData = jobCategoryData.filter((item) => item.description !== '领导力族');
          jobFilter.data = jobCategoryData;
          this.setState({
            renderTableList: true,
          });
        });
      }
      if (jobFilter.key === 'position') {
        this.fetchPosition({ websiteId })
          .then((res) => {
            const data = this.initData(res, lang);
            jobFilter.data = jobFilter.data.concat(data);
            this.props.saveJobFilters(jobFilters);
          });
      }
    });
    this.props.saveJobFilters(jobFilters);
  }

  componentDidMount() {
    const { data, pageContext } = this.props;
    const copywriting = data.contentfulCopywriting;
    // console.log('copywriting');
    // console.log(copywriting);
    // 误删配置默认展示中文招聘信息
    const websiteId = copywriting.config.websites
      ?.find((website) => website.key === 'campusRecruitment')?.id || '6962795203808168199';
    const { validJobFilters } = copywriting.common;
    const { job_filters, banner } = copywriting.campusRecruitment;
    const providedJobFilters = job_filters;
    // TODO 优化参数
    this.initJobFilters(providedJobFilters, validJobFilters,
      { websiteId, copyright: copywriting.common, lang: pageContext.language });

    banner.map((image) => { image.img = image[getDevice()]; });
    this.setState({ banner, websiteId, activeFilter: job_filters[0] });
    // 切换设备
    window.addEventListener('resize', throttle(() => {
      banner.map((image) => { image.img = image[getDevice()]; });
      this.setState({ banner });
    }, 500));
    const searchKeywords = getKeyWord(this.props, 'wd');
    this.setState({ searchKeywords });
  }

  chooseItem(index) {
    console.warn('index', index);
  }

  getFilterJobList() {
    const { allChooseContent } = this.props;
    const { category, position } = allChooseContent;
    this.props.changeIsLoadingAllData(false);

    this.setState({
      jobad_jobcategory: category,
      jobad_workingplace: position,
      showChooseList: false,
    });
  }

  popUp(type) {
    this.setState({
      showChooseList: true,
    });
    this.setState({
      activeFilter: type,
    });
  }

  hiddenPopup() {
    this.setState({
      showChooseList: false,
    });
  }

  render() {
    const {
      location,
      jobFilters,
      data,
      pageContext,
    } = this.props;
    const copywriting = data.contentfulCopywriting;
    const { banner } = this.state;
    const {
      showChooseList, jobad_jobcategory, jobad_workingplace, searchKeywords,
    } = this.state;
    const { seo, sidebar } = copywriting.campusRecruitment;
    const { pathname } = location || {};
    const lang = pageContext.language;
    return (
      <div className={styles.universitiesRecruitmentPage}>
        <Swipe items={banner} mod="home" />
        <Layout {...this.props} copywriting={copywriting}>
          <SEO title={seo.title} keywords={seo.keywords} description={seo.description} />
          <div className={styles.content}>
            <Sidebar pathname={pathname} temp={sidebar} />
            <div className={styles.Recruitment}>
              <div className={styles.contentBox}>
                <div className={styles.chooseLists}>
                  {jobFilters && jobFilters.map((filter) => <ChooseList
                    key={filter.key}
                    title={filter.activeDescription || filter.description}
                    jobFilter={filter}
                    type={filter.key}
                    popUp={this.popUp.bind(this)}
                    getFilterJobList={this.getFilterJobList.bind(this)} />)
                  }
                </div>
                <div className={styles.placeholder}></div>
                {
                  this.state.renderTableList
                    ? <FSTableList
                      tableHeader={copywriting.common.job_info_header}
                      type="universities"
                      lang={lang}
                      copyright={{ ...copywriting.common, ...copywriting.jobTable }}
                      websiteId={this.state.websiteId}
                      jobad_jobcategory={jobad_jobcategory}
                      jobad_workingplace={jobad_workingplace}
                      jobad_keywords={searchKeywords} />
                    : <div className="loading">
                      <img className="loadingContent" src="https://media.number-7.cn/ebike-h5/static/images/common/loading.gif" alt="" />
                    </div>

                }
              </div>
            </div>
          </div>
        </Layout>
        {showChooseList && <MobileDropdownChooseList
          copywriting={copywriting.search}
          location={location}
          activeFilter={this.state.activeFilter}
          hiddenPopup={this.hiddenPopup.bind(this)}
          getFilterJobList={this.getFilterJobList.bind(this)}
        >
        </MobileDropdownChooseList>}
      </div>
    );
  }
}

// export default Recruitment;
export default connect(
  (state) => ({
    jobFilters: state.app.jobFilters,
    allChooseContent: state.app.allChooseContent,
    isLoadingAllData: state.app.isLoadingAllData,
  }),
  (dispatch) => ({
    saveJobFilters: (open) => dispatch(saveJobFilters(open)),
    changeIsLoadingAllData: (open) => dispatch(changeIsLoadingAllData(open)),
  }),
)(Recruitment);

export const query = graphql`
query campusQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    footer {
      brands {
        link
        iconfont
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
        children {
          menuId
          name
          url
        }
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    config {
      websites {
        key
        id
        description
      }
    }
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
      multiple_description
      validJobFilters {
        description
        key
      }
      filter_all_description
      filter_all_children_prefix
      filter_all_children_suffix
      job_info_header{
        id
        text
        isSort
        default
      }
    }
    campusRecruitment {
      seo {
        description
        keywords
        title
      }
      job_filters
      sidebar {
        text
        url
      }
      banner {
        mobile
        pc
        jump_link
      }
    }
    jobTable {
      last_page
      next_page
      empty_placeholder
    }
  }
} 
`;
